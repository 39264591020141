

<div *ngIf="!mobile" style="background-color: #4687BF; height:100%;width:100%;">
 
  <div class="row">
    <div style="display: flex;justify-content: center; width: 100%;">
      <!-- Header (start here) -->
    
      
      <div style="width: 100%; display: flex;flex-direction: row; position: fixed; height: 100%;">
        <div style="width: 50%; background-color: #fff; border-top-right-radius:5px;border-bottom-right-radius:5px;">
          <!-- Content section (start here) -->
          <div class="outer">
            <div class="middle">
              <section class="inner">
                <!-- Login (start here) -->
                <div *ngIf="loginSec">
                  
                  <div class="login-wrapper">
                    <div style="display: flex;justify-content: center;">
                      <img src="assets/images/copious-logo.png" alt=""
                        style="width:30%;height:30%;align-items: center; margin: -20px 0px;" />
                    </div>
                    <form>
                      <div
                        style=" display: flex; flex-direction: column; justify-content:center;align-items: center; padding-top:0px;color:black;">
                        <div style="text-align: left; margin-right: 60px;margin-top: 90px;">
                       
                          <h4 style="font-size:40px;  color: #485870;">Login </h4>
                          
                          <div style="font-size: 20px; color: #486D91;">Enter your <span
                              style="color: #486D91; font-weight: 600;">registered
                              mobile number</span></div>
                          <div style="font-size: 20px;color: #486D91; margin-top: -4px;">In our secure world of
                            healthcare
                          </div>
                        </div>
                      </div>
                      <div style="display: flex;justify-content: center; margin: 18px 0px; margin-bottom: 0px;">
                        <input type="text" id="phoneNumberId" name="firstname" placeholder="xxxxx-xxxxx"
                          [(ngModel)]="phoneNumber" name="phoneNumber" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="10"
                          style="font-size: 22px; color: #45566e; font-weight: 600;letter-spacing: 5px; text-align: center;">
                      </div>
                      <div style="display: flex;justify-content: center;">
                        <div *ngIf="!loginValidateBlank && !loginValidateEmpty && !loginValidateLength" style="padding:13px;"></div>
                        <div *ngIf="loginValidateEmpty" style="color:red">Please Enter Phone Number</div>
                        <div *ngIf="loginValidateBlank" style="color:red">Please Enter Phone Number</div>
                        <div *ngIf="loginValidateLength" style="color:red">Please Enter 10 Digit of Phone Number</div>
                        <div *ngIf="userExist" style="color:red">User Not Exists Please Register.</div>
                      </div>
                      <div style="display: flex;justify-content: center;">
                        <button *ngIf="sentOtpBtn" class="button" (click)="checkPhoneNumber()"
                          style="width: 245px; margin: 8px 0px; outline: none;"
                          (click)="loginValidate(phoneNumber)">Send OTP</button>
                        
                          <button *ngIf="loginBtnLoading" class="buttonload button" style="width: 245px; margin: 8px 0px; outline: none;">
                            <i class="fa fa-spinner fa-spin"></i>&nbsp;Loading
                          </button>
                         
                      </div>
                      

                   
                      <div
                        style="display: flex;justify-content: bottom;  color: #99a6bb; font-weight: bold; font-size: 18px; position: absolute; bottom: 0; display: flex; justify-content: center; align-items: center; width: 100%; margin-bottom: 16px;">
                        &#64;2021 All Right Reserved
                      </div>
                      
                    </form>
                  </div>
                </div>

                <!-- Pawword (start here) -->
                <!-- <div *ngIf="passwordSec">
          <div class="newLoginHeader"> <label class="newLoginLabel">Enter password to
              login</label></div>
          <div class="login-wrapper">
            <form>

              <div style=" width: 70%;align-self: center;margin-left: 15%;margin-top: 7%;">
                <label class="newInputStyleLabel">Enter Password</label>
                <input (keydown.space)="$event.preventDefault()" (cut)="$event.preventDefault()"
                  (copy)="$event.preventDefault()" (paste)="$event.preventDefault()" type="password"
                  [(ngModel)]="password" id="passwordInput" name="password" class="form-control newInputBox"
                  (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" style=" letter-spacing: 11px;
                  text-align: center;
                  font-size: 40px !important;">

              </div>
              <div style="margin-left: 62%;margin-top: 3%;">
                <a style="color:#ff8e14; " [routerLink]="['/authentication/forgot']" class="forgot-password">Forgot
                  password?</a>
              </div>
              <button type="submit" class="opd-primary-btn btn-md w125 newBtnStyle"
                style="margin-left: 60%;margin-top: 5%;" (click)="checkPassword()">Next</button>
            </form>
          </div>
        </div> -->

                <!-- OPT (start here) -->
                <div *ngIf="OTPsec">

                  <div
                    style="display: flex;flex-direction: row; justify-content: space-between;width: 100%; margin: 40px 0px; padding-top:0px;">
                    <div (click)="backLoginPage();" style="padding: 25px 15px;">
                      <button style=" background-color: #fff; width: 50px; height: 50px;
                  border-radius: 8px;
                  outline: none;
                  border: 1px solid #f3f2f6;"><img src="assets/images/arrow-back.svg"
                          style="height:20px;width: 30px;"></button>
                    </div>
                    <img src="assets/images/copious-logo.png" alt="" style="width:30%;height:30%; margin-top: 65px;" />
                    <div style="padding: 25px 0px;">
                      <button style="    background-color: #fff; width: 50px; height: 50px;
                  border-radius: 8px;
                  margin: 0px 15px;
                  outline: none;
                  border: 1px solid #f3f2f6;">
                        <div id="countdown" style="color: #3774bb; font-size: 20px; font-weight: 600;" *ngIf="!reSendTimeFlag">{{time}}</div>
                        <div id="countdown" style="color: #3774bb; font-size: 20px; font-weight: 600;" *ngIf="reSendTimeFlag">{{reSendTime}}</div>
                      </button>
                    </div>
                  </div>

                  <div
                    style="display:flex;flex-direction:column; justify-content:center;align-items:center; color:black;">
                    <div style="text-align: left; margin-right: -19px;padding-top: 30px;">
                      <h4 style="font-size:40px;  color: #485870;">Verify OTP </h4>
                      

                      <div style="font-size: 20px; color: #486D91; ">Enter verification code sent to <span
                          style="color: #486D91; font-weight: 600;">
                          +{{user.phone_number}}</span></div>

                    </div>
                  </div>
                  <div style="display: flex;justify-content: center; margin: 20px 0px;margin-bottom: 0px;" class="form">

                    <div class="newOpt">

                      <input id="firstOtp" class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optFirst" type="text"
                        (keyup)='tabChange(1)' maxlength=1 placeholder="&#8212;" autofocus>&nbsp;&nbsp;

                      <input class="otp" name="otp" (keypress)="validateNumber($event)" [(ngModel)]="optSecond"
                        type="text" (keyup)='tabChange(2)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optThird" type="text"
                        (keyup)='tabChange(3)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optFourth" type="text"
                        (keyup)='tabChange(4)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optFifth" type="text"
                        (keyup)='tabChange(5)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optSixth" type="text"
                        (keyup)='tabChange(6)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                    </div>
                  </div>
                  <div ></div>
                  <div style="display: flex;justify-content: center;">
                    <div *ngIf="OTPnotvalid" style="color:red">Please Enter Valid OTP.</div>
                    <div *ngIf="!OTPnotvalid" style="padding:13px;"></div>
                  </div>
                  <div style="display: flex;justify-content: center;">
                    <button *ngIf="verifyOtp" class="button" (click)="confirmOTP()"
                      style="width: 245px; margin: 8px 0px; outline: none; ">Verify</button>
                      <button *ngIf="verifyBtnLoading" class="buttonload button" style="width: 245px; margin: 8px 0px; outline: none;">
                        <i class="fa fa-spinner fa-spin"></i>&nbsp;Loading
                      </button>
                  </div>
                  <div style="display: flex;flex-direction: column; align-content: space-between; justify-content: space-between; height: 35vh;
        ">
                    <div *ngIf="time==0" (click)="resendOTP()"
                      style="cursor: pointer; display: flex;justify-content: center;  color: #1c60ce; font-weight: bold; margin: 15px 0px;">
                      Send Code Again
                    </div>
                    <div *ngIf="time > 0"
                    style="cursor: pointer; display: flex;justify-content: center;  color: #99a6bb; font-weight: bold; margin: 15px 0px;">
                    Send Code Again
                  </div>
                    <div
                      style="display: flex;justify-content: center;  color: #99a6bb; font-weight: bold; font-size: 18px; position: absolute; bottom: 0; display: flex; justify-content: center; align-items: center; width: 50%; margin-bottom: 16px;">
                      &#64;2021 All Right Reserved
                    </div>
                  </div>
                  <div style="padding-top:120px;text-align: center;">
                    <div style="color: #fff;padding-top:5px;">
                      <h4 style="color: #fff;">Enter OTP to login in your account</h4>
                      <h5>OTP sent to your number XXX-XXX-X{{user.phone_number.slice(-3)}}</h5>
                    </div>

                    <div style="margin-top: 0%;">
                      <span style="color: #fff;font-size: 14px;font-weight: bold;"></span>
                    </div>

                    <div style="padding-top:32px;padding-bottom: 25px;">
                      <form>
                        <div
                          style=" width: 100%;align-self: center;margin-top: 6%;color: #fff;padding-left: 80px;padding-right: 80px;">
                          <label style="color: #fff;font-size: 26px;">Enter OTP</label>
                          <input type="text" class="form-control" maxlength="6" [(ngModel)]="otp" name="otp" id="otp"
                            (keypress)="validateNumber($event)" style="text-align: center;
                      font-size: 20px;
                      letter-spacing: 12px;">
                        </div>
                        <div style="padding-top: 9px;padding-bottom: 9px;">
                          <a id="resend-otp" (click)="resendOTP()" style="color:#ff8e14; ">Resend
                            OTP?</a> <span> if you don't receive it in 45 seconds.</span>
                        </div>

                        <button type="submit"
                          style="border: none;background-color: #fff;padding: 10px 25px;border-radius: 4px;"
                          (click)="confirmOTP()">Login</button>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div
          style=" display: flex; justify-content:center;
          align-items:center;
          width: 50%; height: 100%; background-color: #3d77c7; color:#fff; border-top-left-radius:5px;border-bottom-left-radius:5px;">
          <div>

            <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows"
              [showNavigationIndicators]="showNavigationIndicators">
              <ng-template ngbSlide *ngFor="let image of slides">
                <div class="picsum-img-wrapper" style="background-color: transparent; text-align: center;">
                  <img src={{image.image}} alt="Random slide" style="height: 500px; width: 500px; ">
                </div>
                <div class="carousel-caption">

                </div>

                <div style="display: flex; justify-content: center; align-items: center; margin-top: 30px;">
                  <h3 style="color:#FDFDFD">{{image.heading}}</h3>
                </div>
                <div
                  style="display: flex; justify-content: center; align-items: center; font-size: 18px; font-weight: bold; color: #E1EBF4;">
                  {{image.description}}</div>

              </ng-template>
            </ngb-carousel>

            



          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<div *ngIf="mobile" style=" height:100%;width:100%;">
 
    <div style="display: flex;justify-content: center; width: 100%;">
      <div style="width: 100%; display: flex;flex-direction: column; position: fixed; height: 100%;">
        <div style="width: 100%; background-color: #fff; border-top-right-radius:5px;border-bottom-right-radius:5px;">
          <!-- Content section (start here) -->
          <div class="outer">
            <div class="middle">
              <section class="inner">
                <!-- Login (start here) -->
                <div *ngIf="loginSec"  style="margin-top: -79px;">
                  
                  <div class="login-wrapper">
                    <div style="display: flex;justify-content: center; width: 111vw;">
                      <img src="assets/images/copious-logo.png" alt=""
                        style="width:68%;height:50%;align-items: center;" />
                    </div>
                    <div style="display: flex;justify-content: center; width: 111vw; margin-top: 20px;">
                      <h4 style="font-size:40px;  color: #485870;">Login </h4>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center; width: 111vw; margin-top: 30px;">
                      <div style="font-size: 20px; color: #486D91;">Enter your <span
                        style="color: #486D91; font-weight: 600;">registered
                        mobile number</span></div>
                    <div style="font-size: 20px;color: #486D91; margin-top: -4px;">In our secure world of
                      healthcare
                    </div>
                    </div>
                    <form>
                    
                      <div style="display: flex;justify-content: center; margin: 18px 0px; margin-bottom: 0px;">
                        <input type="text" id="phoneNumberId" name="firstname" placeholder="xxxxx-xxxxx"
                          [(ngModel)]="phoneNumber" name="phoneNumber" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="10"
                          style="font-size: 22px; color: #45566e; font-weight: 600;letter-spacing: 5px; text-align: center;">
                      </div>
                      <div style="display: flex;justify-content: center;">
                        <div *ngIf="!loginValidateBlank && !loginValidateEmpty && !loginValidateLength" style="padding:13px;"></div>
                        <div *ngIf="loginValidateEmpty" style="color:red">Please Enter Phone Number</div>
                        <div *ngIf="loginValidateBlank" style="color:red">Please Enter Phone Number</div>
                        <div *ngIf="loginValidateLength" style="color:red">Please Enter 10 Digit of Phone Number</div>
                        <div *ngIf="userExist" style="color:red">User Not Exists Please Register.</div>
                      </div>
                      <div style="display: flex;justify-content: center;">
                        <button *ngIf="sentOtpBtn" class="button" (click)="checkPhoneNumber()"
                          style="width: 245px; margin: 8px 0px; outline: none;"
                          (click)="loginValidate(phoneNumber)">Send OTP</button>
                        
                          <button *ngIf="loginBtnLoading" class="buttonload button" style="width: 245px; margin: 8px 0px; outline: none;">
                            <i class="fa fa-spinner fa-spin"></i>&nbsp;Loading
                          </button>
                         
                      </div>
                      

                   
                      <div
                        style="display: flex;justify-content: bottom;  color: #99a6bb; font-weight: bold; font-size: 18px; position: absolute; bottom: 0; display: flex; justify-content: center; align-items: center; width: 100%; margin-bottom: 16px;">
                        &#64;2021 All Right Reserved
                      </div>
                      
                    </form>
                  </div>
                </div>

                
                <!-- OPT (start here) -->
                <div *ngIf="OTPsec" style="margin-top: -79px;">

                  <div
                    style="display: flex;flex-direction: row; justify-content: space-between;width: 111vw;  padding-top:0px;">
                    <div (click)="backLoginPage();" style="padding: 25px 15px;">
                      <button style=" background-color: #fff; width: 50px; height: 50px;
                  border-radius: 8px;
                  outline: none;
                  border: 1px solid #f3f2f6;"><img src="assets/images/arrow-back.svg"
                          style="height:20px;width: 30px;"></button>
                    </div>
                    <img src="assets/images/copious-logo.png" alt="" style="width:60%;height:60%; margin-top: 65px;" />
                    <div style="padding: 25px 0px;">
                      <button style="    background-color: #fff; width: 50px; height: 50px;
                  border-radius: 8px;
                  margin: 0px 15px;
                  outline: none;
                  border: 1px solid #f3f2f6;">
                        <div id="countdown" style="color: #3774bb; font-size: 20px; font-weight: 600;" *ngIf="!reSendTimeFlag">{{time}}</div>
                        <div id="countdown" style="color: #3774bb; font-size: 20px; font-weight: 600;" *ngIf="reSendTimeFlag">{{reSendTime}}</div>
                      </button>
                    </div>
                  </div>

                  <div
                    style="display:flex;flex-direction:column; justify-content:center;align-items:center; color:black;">
                    <div style="text-align: left; margin-right: -19px;padding-top: 30px;">
                      <h4 style="font-size:40px;  color: #485870;">Verify OTP </h4>
                      

                      <div style="font-size: 20px; color: #486D91; ">Enter verification code sent to <span
                          style="color: #486D91; font-weight: 600;">
                          +{{user.phone_number}}</span></div>

                    </div>
                  </div>
                  <div style="display: flex;justify-content: center; margin: 20px 0px;margin-bottom: 0px;" class="form">

                    <div class="newOpt">

                      <input id="firstOtp" class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optFirst" type="text"
                        (keyup)='tabChange(1)' maxlength=1 placeholder="&#8212;" autofocus>&nbsp;&nbsp;

                      <input class="otp" name="otp" (keypress)="validateNumber($event)" [(ngModel)]="optSecond"
                        type="text" (keyup)='tabChange(2)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optThird" type="text"
                        (keyup)='tabChange(3)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optFourth" type="text"
                        (keyup)='tabChange(4)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optFifth" type="text"
                        (keyup)='tabChange(5)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                      <input class="otp" (keypress)="validateNumber($event)" [(ngModel)]="optSixth" type="text"
                        (keyup)='tabChange(6)' maxlength=1 placeholder="&#8212;">&nbsp;&nbsp;
                    </div>
                  </div>
                  <div ></div>
                  <div style="display: flex;justify-content: center;">
                    <div *ngIf="OTPnotvalid" style="color:red">Please Enter Valid OTP.</div>
                    <div *ngIf="!OTPnotvalid" style="padding:13px;"></div>
                  </div>
                  <div style="display: flex;justify-content: center;">
                    <button *ngIf="verifyOtp" class="button" (click)="confirmOTP()"
                      style="width: 245px; margin: 8px 0px; outline: none; ">Verify</button>
                      <button *ngIf="verifyBtnLoading" class="buttonload button" style="width: 245px; margin: 8px 0px; outline: none;">
                        <i class="fa fa-spinner fa-spin"></i>&nbsp;Loading
                      </button>
                  </div>
                  <div style="display: flex;flex-direction: column; align-content: space-between; justify-content: space-between; height: 35vh;
        ">
                    <div *ngIf="time==0" (click)="resendOTP()"
                      style="cursor: pointer; display: flex;justify-content: center;  color: #1c60ce; font-weight: bold; margin: 15px 0px;">
                      Send Code Again
                    </div>
                    <div *ngIf="time > 0"
                    style="cursor: pointer; display: flex;justify-content: center;  color: #99a6bb; font-weight: bold; margin: 15px 0px;">
                    Send Code Again
                  </div>
                    <div
                      style="display: flex;justify-content: center;  color: #99a6bb; font-weight: bold; font-size: 18px; position: absolute; bottom: 0; display: flex; justify-content: center; align-items: center; width: 50%; margin-bottom: 16px;">
                      &#64;2021 All Right Reserved
                    </div>
                  </div>
                  <div style="padding-top:120px;text-align: center;">
                    <div style="color: #fff;padding-top:5px;">
                      <h4 style="color: #fff;">Enter OTP to login in your account</h4>
                      <h5>OTP sent to your number XXX-XXX-X{{user.phone_number.slice(-3)}}</h5>
                    </div>

                    <div style="margin-top: 0%;">
                      <span style="color: #fff;font-size: 14px;font-weight: bold;"></span>
                    </div>

                    <div style="padding-top:32px;padding-bottom: 25px;">
                      <form>
                        <div
                          style=" width: 100%;align-self: center;margin-top: 6%;color: #fff;padding-left: 80px;padding-right: 80px;">
                          <label style="color: #fff;font-size: 26px;">Enter OTP</label>
                          <input type="text" class="form-control" maxlength="6" [(ngModel)]="otp" name="otp" id="otp"
                            (keypress)="validateNumber($event)" style="text-align: center;
                      font-size: 20px;
                      letter-spacing: 12px;">
                        </div>
                        <div style="padding-top: 9px;padding-bottom: 9px;">
                          <a id="resend-otp" (click)="resendOTP()" style="color:#ff8e14; ">Resend
                            OTP?</a> <span> if you don't receive it in 45 seconds.</span>
                        </div>

                        <button type="submit"
                          style="border: none;background-color: #fff;padding: 10px 25px;border-radius: 4px;"
                          (click)="confirmOTP()">Login</button>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <!-- <div
          style=" display: flex; justify-content:center;
          align-items:center;
          width: 50%; height: 100%; background-color: #3d77c7; color:#fff; border-top-left-radius:5px;border-bottom-left-radius:5px;">
          <div>

            <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows"
              [showNavigationIndicators]="showNavigationIndicators">
              <ng-template ngbSlide *ngFor="let image of slides">
                <div class="picsum-img-wrapper" style="background-color: transparent; text-align: center;">
                  <img src={{image.image}} alt="Random slide" style="height: 500px; width: 500px; ">
                </div>
                <div class="carousel-caption">

                </div>

                <div style="display: flex; justify-content: center; align-items: center; margin-top: 30px;">
                  <h3 style="color:#FDFDFD">{{image.heading}}</h3>
                </div>
                <div
                  style="display: flex; justify-content: center; align-items: center; font-size: 18px; font-weight: bold; color: #E1EBF4;">
                  {{image.description}}</div>

              </ng-template>
            </ngb-carousel>

            



          </div>
        </div> -->

      </div>
  </div>
 
</div>
<ng-template #activationError let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Select Clinic</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content ">
        <div class="row ml-0">
          <label for="" class="errorMsg">
            <i class="ng-tns-c1-18 fa fa-info-circle" ng-reflect-klass="fa fa-question-circle" ng-reflect-ng-class="fa">
            </i> Sorry, your account has been deactivated by the Admin.<br> Please connect with us at <br> <a
              class="supportLink">support&#64;copiousclinics.com </a> for any queries.
          </label>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn mr-2 opd-primary-btn transformNormal btn-md upprcase" (click)="d()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-confirmDialog class="login" appendTo="body"></p-confirmDialog>

