import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LabMenuItems } from '../../shared/menu-items/menu-items';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from '../../services/patient.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const SMALL_WIDTH_BREAKPOINT = 74;
@Component({
  selector: 'app-new-rx-sidebar',
  templateUrl: './new-rx-sidebar.component.html',
  styleUrls: ['./new-rx-sidebar.component.scss']
})
export class NewRxSidebarComponent implements OnInit {

  isDocked = false;
  private _opened: boolean = false;
  isBoxed = false;
  isOpened = true;
  shareLinkActive: boolean = false;
  mode = 'push';
  _mode = this.mode;
  _autoCollapseWidth = 74;
  width = window.innerWidth;
  selectedIndex = 0;
  theme = 'light';
  mobileNumber: any;

  
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  
   
    constructor(  private authService: AuthService,  public menuItems:LabMenuItems,  public dialog: MatDialog ,private patientService: PatientService, private modalService: NgbModal,) { 
  
  }
  @HostListener("window:scroll", ["$event"])
  ngOnInit(): void {
    if (this.isOver()) {
      this._mode = "push";
      this.isOpened = false;
    }
  }

  openDialog(content:any) {

    this.dialog.open(content);
    this.shareLinkActive = true;
  }
  closeDialog() {
    this.dialog.closeAll()
    this.shareLinkActive = false;

  }

  shareLinkApp() {
    const postData = {
      mobileNumber: this.mobileNumber,
    }
    this.patientService.sendSmsOfAppInvite(postData).subscribe((response:any) => {
      if (response) {

        console.log(response)
        this.modalService.dismissAll();
      }
    })

  }
  validateNumber(e:any) {
    return (
      (e.charCode > 47 && e.charCode < 58) ||
      e.charCode == 46 ||
      e.which == 8 ||
      e.which == 13 ||
      e.keyCode == 9
    );
  }
  isOver(): boolean {
    return window.matchMedia(`(max-width: 74px)`).matches;
  }
  removeData(idx:any) {
    if (idx != null) {
      this.selectedIndex = idx;
    }
    // this.storageService.removeData();
    // this.storageService.removeStage();
  }
  logout() {
    localStorage.removeItem("lock");
    this.authService.deleteToken(0);
  }


}
