import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import $ from 'jquery';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  credentials: any
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/

  constructor(private adminService: AdminService, private router: Router, private toastr: ToastrService) {


  }

  ngOnInit() {
    this.credentials = { user_type: "Admin" }
  }
  forgotPassword() {
    this.credentials.date = new Date()
    this.adminService.adminforgotPassword(this.credentials).subscribe((response:any) => {

      if (response.success == true) {
        this.credentials = {}
        this.toastr.success(response.message)
      } else {
        this.toastr.error(response.error)

      }
    })
  }
  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

}
