import { Component, OnInit, ViewChild, HostListener, } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LabMenuItems } from '../../shared/menu-items/menu-items';
const SMALL_WIDTH_BREAKPOINT = 991;
export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}
@Component({
  selector: 'app-layout',
  templateUrl: './lab-sidebar.component.html',
  styleUrls: ['./lab-sidebar.component.css']
})
export class LabSidebarComponent implements OnInit {
  isDocked = false;
  private _opened: boolean = false;
  isBoxed = false;
  isOpened = true;
  
  mode = 'push';
  _mode = this.mode;
  _autoCollapseWidth = 991;
  width = window.innerWidth;
  selectedIndex = 0;
  theme = 'light';
  
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  
  constructor(  private authService: AuthService,  public menuItems:LabMenuItems,) { 
  
  }
  @HostListener("window:scroll", ["$event"])
  ngOnInit(): void {
    if (this.isOver()) {
      this._mode = "push";
      this.isOpened = false;
    }
  }
  isOver(): boolean {
    return window.matchMedia(`(max-width: 991px)`).matches;
  }
  removeData(idx:any) {
    if (idx != null) {
      this.selectedIndex = idx;
    }
    // this.storageService.removeData();
    // this.storageService.removeStage();
  }
  logout() {
    localStorage.removeItem("lock");
    this.authService.deleteToken(0);
  }
}
