<!-- <div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div> -->
<div class="app opd-main-wrapper"
  [ngClass]="{
    'mode-boxed': isBoxed, 
    'sidebar-opened': isOpened, 
    'theme-light': theme == 'light', 
    'theme-dark': theme == 'dark', 
    'mode-push': _mode == 'push', 
    'mode-dock': _mode == 'dock', 
    'mode-over': _mode == 'over', 
    'mode-slide': _mode == 'slide'
  }"
  style="background-color: #fff; min-width: none !important;">
  <p-sidebar 
    [(visible)]="isOpened" 
    [position]="'left'" 
    [baseZIndex]="1000" 
    [dismissable]="true" 
    [modal]="isOver()" 
    [style]="{ width: _mode === 'dock' ? '80px' : '250px' }"
    [styleClass]="'sidebar-panel'" 
    (onHide)="isOpened = false">
    <nav
      style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px; ">
      <a [routerLink]="['/doctor/homepage']" title="home">
        <img _ngcontent-c0="" alt="" width="50px" src="assets/images/new_logo.svg">

      </a>

    </nav>


    <!-- main navigation -->
    <nav
      style="background-color: #fff; font-size: 14px; text-transform: uppercase; flex-direction: column; display: flex; border-right: 1px solid #eee;"
      class="menu">
      <!-- style="background-color: #fff; margin-right: 0px; padding-right: 0px;" -->


      <div class="icon-container">
          <a [routerLink]="['/doctor/homepage']">
            <img class="icon" src="assets/images/home_leftbar_off.svg" alt="Home" title="Home">
          </a>
      </div>

      <div class="icon-container"  style="background-color: #E2ECFB; border-radius: 8px;">
          <!-- <a [routerLink]="['/newrx/rxHomePage']"> -->
            <img class="icon" src="assets/images/prescription-solid.svg " alt="Rx" title="Rx">
          <!-- </a> -->
      </div>


      <div class="icon-container" >
          <a [routerLink]="['/doctor/viewPrescriptions']">
            <img class="icon" src="assets/images/leftbar_prescription_off.svg" alt="viewRx"
              title="ViewRx">
          </a>
      </div>


      <div class="icon-container" >
          <a [routerLink]="['/doctor/profileView']">
            <img class="icon" src="assets/images/new_settings_topbar.svg"   alt="setting"
              title="Setting">

          </a>
      </div>

      <!-- Reports icon pending -->


      <!-- <div class="sideIcon">
        <div>
          <img src="assets/images/new_documents_topbar_off.svg" alt="user"
            title="user" style="width: 22px;">
        </div>
      </div> -->
      
      <div class="icon-container">
          <a [routerLink]="['/doctor/favouriteManagement']">
            <img class="icon" src="assets/images/favourite_grayButton.svg "   alt="favourite"
              title="Favourite">
          </a>
      </div>




      <div class="icon-container" >
          <a [routerLink]="['/doctor/vaccineInventory']">
            <img src="assets/images/inventory.svg" class="icon" alt="share" title="Share">
        </a>
      </div>

      <div class="icon-container" >
          <a (click)="openDialog(share_Link_App);"  [class.active]="shareLinkActive">
            <img src="assets/images/share_link.svg" class="icon" alt="share" title="Share">
          </a>
      </div>

      <!-- SHARE APP LINK -->

    </nav>




    <!-- <nav class="menu"  style="background-color: #fff; ">

    
      <a class="navigation-link" (click)="logout()" style="height: 50px !important">
        <div style="flex-direction: row; display: flex;  align-self: center;">

          <img src="assets/images/logout.svg" class="navbar-avatar rounded-circle svg" alt="user" title="user">

          <div style="margin-left: 6px; font-size: 18px" [ngStyle]="{'color' : false ? '#4686be'  :'#8B8B99'}">
            Logout</div>
        </div>
      </a>

    </nav> -->
    <!-- /main navigation -->
  </p-sidebar>

  <ng-template #share_Link_App let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Share Health App Link</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="width: 98%;
        padding-left: 5px;
        margin-top: 14px;
        margin-bottom: 4px;
        margin-left: 8px;">
            <div class="row">
                <label class="fieldsetlable" style="margin-bottom: -14px;">Enter Complete mobile number of
                    patient</label>
                <input type="text" class="form-control" [maxLength]="10" (keypress)="validateNumber($event)"
                    style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px; height: 50px;"
                    [(ngModel)]="mobileNumber">

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div (click)="shareLinkApp(); closeDialog()" class="btn-type-filled">Send</div>
    </div>
</ng-template>

  <div p-sidebar-content class="app-inner">



    <div class="main-content">

      <router-outlet></router-outlet>

    </div>
  </div>

</div>



<!-- utility dower popup designs  -->





<!-- utility popups ends he -->





<!-- 
<ul class="navigation" appAccordion>

  <li class="navigation-item" appAccordionLink *ngFor="let menuitem of menuItems.getAll()"
    group="{{menuitem.state}}">
    <a class="navigation-link" routerLinkActive="active"
      (click)="otpSection = false;  removeData();" [routerLink]="['/doctor/homepage']"
      *ngIf="menuitem.type === 'link'" style="color: #000;">
    
    </a>

    <a class="navigation-link" routerLinkActive="active"
      (click)="otpSection = false; removeData(menuitem.name=='Home' ? 0 : 1);" appAccordionToggle
      [routerLink]="['/doctor/homepage']" *ngIf="menuitem.type === 'extLink'"
      style="height: 52px !important; border-radius: 6px; width: 52px; display: flex; justify-content: center; align-items: center;"
      [ngStyle]="{'background-color' : selectedIndex == 0 && menuitem.name=='Home' ? '#E2ECFB' : selectedIndex == 1 && menuitem.name=='View Rx' ? '#E2ECFB' : 'transparent'}">

      <div style="flex-direction: column; display: flex; justify-content: center; width: 55px;">
          <div style="display: flex;justify-content: center;">
            <mat-icon class="blue-icon" *ngIf="menuitem.name=='Home' && selectedIndex == 0" 
            alt="user" title="Home">home</mat-icon>
          <mat-icon *ngIf="menuitem.name=='Home' && selectedIndex != 0" 
             alt="user" title="user">home</mat-icon>

         
      
        <mat-icon *ngIf="menuitem.name=='Settings'  && selectedIndex == 1" 
        >settings</mat-icon>
        <mat-icon *ngIf="menuitem.name=='Settings' && selectedIndex != 1"
         >settings</mat-icon>
          </div>


      </div>
      <span class="mr-auto"></span>
      <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>


 
  

  

 

 

   
   
  </li>
  <li class="navigation-item" style="margin-left: 22px;"  >
    
      <div (click)="logout()"  style="cursor: pointer; flex-direction: column; display: flex; justify-content: center; width: 55px;">
        
        <mat-icon alt="user" title="Logout">logout</mat-icon>

      
    </div>
  </li>

</ul> -->




<p-confirmDialog class="pConfirmZindex" appendTo="body"></p-confirmDialog>