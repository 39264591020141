import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  HostListener,
  //AnimationTransitionEvent,
  NgZone,
  AfterViewInit
} from "@angular/core";
import { PlatformLocation } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NgbModal, ModalDismissReasons, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { Event } from '@angular/router';

import { MenuItems } from "../../shared/menu-items/menu-items";
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

//import { TranslateService } from "@ngx-translate/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { DoctorService } from "../../services/doctor.service";
import { PatientService } from "../../services/patient.service";
import { UserService } from "../../services/user.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../services/auth.service";
import $ from 'jquery';
import { GlobalService } from "../../services/global.service";
import { StorageService } from "../../services/storage.service";
// import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Constants } from "../../../config/constants";
//import { Subject } from "rxjs/Subject";
import { Subject } from 'rxjs';
import { patientServiceReload } from '../../doctor/patient/patient/patient.service';
import { MatDialog } from '@angular/material/dialog';

const SMALL_WIDTH_BREAKPOINT = 991;
import { cloneDeep } from 'lodash';

import moment from 'moment';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { MatSidenav } from "@angular/material/sidenav";
export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: "app-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
  providers: [NgbDropdown]

})
export class DashboardLayoutComponent
  implements OnInit, OnDestroy, AfterViewInit {
    private _routerSubscription: Subscription = new Subscription();
  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );
  doctorFlag:any;
  mask: any[] = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  @ViewChild('sidenav') sidenav: MatSidenav;

  reason = '';

  data: any;
  currentwebapp: any
  @ViewChild("ViewAllCases") ViewAllCases!: ElementRef;
  @ViewChild("todaysBday") todaysBday!: ElementRef;
  @ViewChild("saveImageModal") saveImageModal!: ElementRef;
  @ViewChild("searchInput") searchInput!: ElementRef;
  cropperSettings: CropperSettings;
  imageError: any;
  currentLang = "en";
  options:any;
  userDetails: any = {};
  theme = "light";
  showSettings = false;
  isDocked = false;
  isBoxed = false;
  isOpened = true;
  mode = "push";
  _mode = this.mode;
  _autoCollapseWidth = 991;
  width = window.innerWidth;
  topHeading: string = "";
  path: string = "";
  vaccineImage = "";
  isEditableVisit = false;
  dischargeNote: String = "";
  dischargeNoteName = "";
  profileImagePath = Constants.APICONFIG.doctorProfileImagePath;
  patientProfileImagePath = Constants.APICONFIG.patientProfileImagePath;
  headingArray: any = {
    profileview: "Clinician's Profile",
    basicinformation: "LET'S GET STARTED",
    signup: "LET'S GET STARTED",
    pasthistory: "PAST HISTORY",
    pasthistorynew: "PAST HISTORY NEW",
    vitals: "VITALS",
    treatment: "PRESCRIPTION",
    investigations: "INVESTIGATIONS",
    nextappointment: "NEXT APPOINTMENT",
    complaints: "COMPLAINTS",
    growthnutrition: "GROWTH & NUTRITION"
  };
  otpSection = false;
  otp:any = null;
  date: any;
  minDate: any;
  error = false;
  modalRef: any = null;
  stdCode: any;
  idle = false;
  password:any = null;
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  birthdayUsers: any[] = [];
  wishListId: any[] = [];
  patientId1: any = null;
  pathArray: any[] = [];
  /*headingArray: any = {
    //all path should be in lower case
    "cliniciansprofile" : "CLINICIAN'S PROFILE",
    "basicinformation" : "LET'S GET STARTED",
    "signup" : "LET'S GET STARTED",
    "dashboard": `Good morning! ${this.doctor}`,
    "profileview": `Good morning! ${this.doctor}`,
    "inventory": `Good morning! ${this.doctor}`,
    "patientbasicinfo": "BASIC INFORMATION",
    "pasthistory": "PAST HISTORY"
  }*/
  todaysDate: any;
  todaysDayOfWeek: any;
  userID: any;
  display: boolean = false;
  cropperDisplay: boolean = false;
  originalImage: any;

  imageData: any;
  profileImage:any = null;
  gender: string = "Male";
  recentlyAuthorizedPatients: any[] = [];
  closeResult: any;
  mainMenu: string = "";
  bloodGroups:any = [];
  user: any = {};
  errors: any = {};
  totalPatients: any;
  offset: any;
  patientId: any = null;
  nextOffset: any;
  limit: number;
  patientsArray: any[] = [];
  searchPatient: any = null;
  timeout: any = null;
  @ViewChild("sidebar") sidebar!: ElementRef;
  @ViewChild("cropper") cropper!: ImageCropperComponent;
  @ViewChild("whatsNew") whatsNew!: ElementRef;
  cases: any;
  patientId_new: any;
  mobileNumber: any;
  cloneCases: any;
  allVisits: any;
  caseIdheader: any;
  openCaseId: string = "2";
  otherCases: Boolean = false;
  caseStatus: any;
  selectedVisit:any = null;
  selectedIndex = 0;
  currentUrl: any[] = [];
  authorizePatient = false;
  phoneNumber:any = null;
  authorizepatientId: any;
  modalName:any = null;
  modalId:any = null;
  utilityData: any = {};
  wishList: any[] = [];
  vaccineImagePath = Constants.APICONFIG.vaccineImagePath;
  dischargeNotePath = Constants.APICONFIG.dischargeNotesPath;
  showLoading = false;
  visits: any;

  vesionArray = [{
    version: 2.7, date: '7/8/2023', title: 'Vaccine Inventory & One more Vital added',
    changeLog: [{
      name: "Vaccine Inventory ",
      feature: "We're excited to introduce a new feature to help doctors efficiently manage their vaccine inventory ! This update brings you a comprehensive Vaccine Inventory Module that simplifies the process of tracking and managing vaccines in your clinic. Stay organised and ensure optimal vaccine availability for your patients. "
    }
      , { name: " One more Vital added", feature: "We have added 'LMP' vital for female gender above the age of 8 years for your convenience." }],
  },

  {
    version: 2.6, date: '4/7/2023', title: 'Growth Milestone & New Patient Overview',
    changeLog: [{ name: 'Growth Milestone & New Patient Overview', feature: 'Added new growth milestone feature for pediatrics' },
    { name: '', feature: 'New patient overview to showcase  patient details elaborately' },
    { name: '', feature: 'Added Government vaccination schedule' }]
  },
  {
    version: 2.5, date: '17/10/2022', title: 'Favourite Management',
    changeLog: [{ name: 'Favourite Management', feature: 'We have created a separate module for favourite management, which allows you to create, update and delete favourite prescriptions anytime.' }, { name: "", feature: 'So you can load a list of your favourite prescription, label it with a name and start giving it out at with speed at the time of your rush hours.' }],
  }, {
    version: 2.4, date: "1/9/2022", title: "Patient's Complaints & Patient Address", changeLog: [{ name: "Patient's Complaints", feature: "We have further detailed chief complaints into its 'prefield' and post fields like 'Recurrent fever with chills for 3 days'. This  type of details are easily acquired", }, { name: "Patient Address", feature: "We have added deatils patient address in her/his profile,which will be helpful in tracing patients,Doctor can segregate common name patinets by having there locations on the top along with names" }
      ,],
  },
  ]
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private userService: UserService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public menuItems: MenuItems,
    private router: Router,
    private route: ActivatedRoute,
    //public translate: TranslateService,
    private modalService: NgbModal,
    private titleService: Title,
    private zone: NgZone,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private location: PlatformLocation,
    private patientServiceReload: patientServiceReload,
    public dialog: MatDialog,

  ) {
    this._router = new Subscription();

    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    // this.mediaMatcher.addListener(mql => zone.run(() => this.mediaMatcher = mql));
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.rounded = true;
    this.cropperSettings.width = 50;
    this.cropperSettings.height = 50;
    this.cropperSettings.croppedWidth = 50;
    this.cropperSettings.croppedHeight = 50;
    this.cropperSettings.canvasWidth = 400;
    this.cropperSettings.canvasHeight = 400;
    this.cropperSettings.cropperDrawSettings.strokeColor = "#b147b1";
    this.data = {};
    this.userID = this.authService.getUser();
    this.offset = 0;
    this.limit = 8;
    this.pathArray = [
      {
        id: 1,
        name: "Basic Information",
      },
      {
        id: 2,
        name: "Past History",
      },
      {
        id: 3,
        name: "Vitals",
      },
      {
        id: 4,
        name: "Complaints",
      },
      {
        id: 5,
        name: "Treatment",
      },
      {
        id: 6,
        name: "Investigations",
      },
      {
        id: 7,
        name: "Growth & Milestone",
      },
      {
        id: 8,
        name: "Next Appointment",
      }
    ]


    // this.setTimeout();
    /*if(localStorage.getItem('lock')){
      this.lockScreen()
    }*/

    location.onPopState(() => {
      this.otpSection = false;
    });
  }
  @HostListener("window:scroll", ["$event"])
  scrollHandler(event: any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = event.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;

    if (
      event.target.offsetHeight + event.target.scrollTop === scrollHeightZoom ||
      event.target.offsetHeight + event.target.scrollTop > scrollHeightZoom ||
      event.target.offsetHeight + event.target.scrollTop == scrollHeight
    ) {
      this.offset = this.offset + this.limit;
      if (this.nextOffset !== 0) {
        this.getPatientList(this.offset, this.limit);
      }
    }
  }
  // @HostListener("window:mousemove") refreshUserState() {
  //   clearTimeout(this.userActivity);
  //   if (this.userID) {
  //     this.setTimeout();
  //   }
  // }

  initPatientView() {
    var req_param = { patient_id: localStorage.getItem('patientId'), doctor_id: this.userID };
    this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.cases = response.patientCase;
        this.cloneCases = cloneDeep(this.cases);
        this.otherCases = false;
        this.open(this.todaysBday, 'todaysBdayPopups')
      }
    });
  }


  onChange(index: any) {
    var caseObj = this.cases[index]
    localStorage.setItem("viewCaseId", caseObj.id);
    localStorage.setItem("viewVisitId", caseObj.Visits[0].id);
    console.log(caseObj);
    localStorage.removeItem("newCaseId");
    localStorage.removeItem("newVisitId");
    //this.getVisits(caseObj.id, localStorage.getItem('patientId'));
  }
  goToLink(path: any, d: any) {
    var caseObj = this.cases[0];
    localStorage.setItem("viewCaseId", caseObj.id);
    localStorage.setItem("viewVisitId", caseObj.Visits[0].id);
    if (path.id === 1) {
      this.router.navigate([`/doctor/patients/patientBasicInfo/view`])
    } else if (path.id === 2) {
      this.router.navigate([`/doctor/patients/pastHistory/view`])
    } else if (path.id === 3) {
      this.router.navigate([`/doctor/patients/vitals/view`]);
    } else if (path.id === 4) {
      this.router.navigate([`/doctor/patients/complaints/view`])
    } else if (path.id === 5) {
      this.router.navigate([`/doctor/patients/treatment/view`])
    } else if (path.id === 6) {
      this.router.navigate([`/doctor/patients/investigations/view`])
    } else if (path.id === 7) {
      this.router.navigate([`/doctor/patients/growthnutrition/view`])
    } else if (path.id === 8) {
      this.router.navigate([`/doctor/patients/nextAppointment/view`])
    }

    if (d) {
      d("close modal");
    }
  }

  getBirthdayUsers() {
    let req_params = { user_id: this.userID };
    this.wishList = [];
    this.wishListId = [];
    this.patientService.getBirthdayUsers(req_params).subscribe((response:any) => {
      if (response.success) {
        this.birthdayUsers = response.users;
      } else {
        this.birthdayUsers = [];
      }
    });
  }

  getUtilityDetails(content: any) {
    this.dischargeNoteName = "";
    let req_params = {
      patientId: this.patientId,
      content: content
    };
    switch (content) {
      case "presentDietPopoup":
      case "highlightsPopoup":
      case "riskFactorsPopoup":
      case "presentParametersPopoup":
      case "VaccinationStatusPopoup":
      case "dischargeNotePopoup": {
        this.patientService
          .getUtilityDetails(req_params)
          .subscribe((response:any) => {
            if (response.success) {
              this.utilityData = response;
              console.log(this.utilityData);
            }
          });

        break;
      }
    }
  }

  openDialog(content: any, index: any) {

    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }

  uploadImage(e: any) {
    // var image:any = new Image()
    var file: File = e.target.files[0];

    //validations for profile image. Max file size allowed = 5MB
    if (
      file.type.match("image.*") ||
      file.type.match("pdf.*") ||
      file.type.match("wordprocessingml.*")
    ) {
      this.dischargeNoteName = file.name;
      var myReader: FileReader = new FileReader();
      var that = this;
      myReader.onloadend = function (loadEvent: any) {
        console.log(loadEvent.target.result);
        that.dischargeNote = loadEvent.target.result;
      };
      console.log(this.dischargeNote);
      myReader.readAsDataURL(file);
    } else {
      this.toastr.error("Invalid file format.");
    }
  }

  uploadDoc(d: any) {
    let req_params = {
      patientId: this.patientId,
      doc: this.dischargeNote,
      docName: this.dischargeNoteName
    };
    this.patientService.uploadDischargeNote(req_params).subscribe((response:any) => {
      if (response.success) {
        this.dischargeNoteName = "";
        this.toastr.success("Discharge Note has been uploaded successfully.");
        d();
      }
    });
  }

  downloadDoc() {
    var anchor = document.createElement("a");

    anchor.setAttribute(
      "href",
      `${this.dischargeNotePath}${this.utilityData.discharge_note}`
    ),
      anchor.setAttribute("target", "_blank");
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  }

  setTimeout() {
    let that = this;
    this.userActivity = setTimeout(() => that.lockScreen(), 9000000000000000000);
  }

  selectvaccine(vaccine: any) {
    this.vaccineImage = vaccine.image
      ? `${this.vaccineImagePath}${vaccine.image}`
      : "";
  }

  addToWishList(e: any, obj: any, i: any) {
    if (e.target.checked) {
      if (this.wishList) {
        this.wishList.push(obj);
        this.wishListId.push(obj.id);
      } else {
        this.wishList = [];
        this.wishListId = [];
        this.wishList.push(obj);
        this.wishListId.push(obj.id);
      }
    } else {
      let index = 0;
      let elementIndex: any = null;
      if (i != null) {
        this.wishList.forEach(element => {
          if (element.id == obj.id) {
            elementIndex = index;
          }
          index = index + 1;
        });
        this.wishList.splice(elementIndex, 1);
        this.wishListId.splice(elementIndex, 1);
      }
    }
  }

  sendWishes(d: any) {
    let req_params = { users: this.wishList, user_id: this.userID };
    this.patientService.sendWishes(req_params).subscribe((response:any) => {
      if (response.success) {
        this.toastr.success("Birthday wishes have been sent successfully");
        this.wishList = [];
        this.wishListId = [];
        if (d) {
          d("close modal");
        }
      } else {
      }
    });
  }

  selectAllUsers(e: any) {
    this.wishList = [];
    this.wishListId = [];
    if (e.target.checked) {
      console.log("1");
      this.wishList = JSON.parse(JSON.stringify(this.birthdayUsers));

      this.wishList.forEach(element => {
        this.wishListId.push(element.id);
      });
      console.log(this.wishListId);
    } else {
      console.log("2");
      this.wishList = [];
      this.wishListId = [];
    }
  }

  lockScreen() {
    // this.idle = false;
    // setTimeout(function () {
    //   document.getElementById("passwordInput").focus();
    // }, 200);
    // localStorage.setItem("lock", "false");
  }

  ngOnInit(): void {
    // Subscribe to router events to handle route changes
    this._routerSubscription = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd) // Filter for NavigationEnd events
      )
      .subscribe((event: Event) => {
        const navEndEvent = event as NavigationEnd; // Cast to NavigationEnd
        this.showSettings = false;
  
        const currentUrl = navEndEvent.url.split("/");
        this.path = currentUrl[currentUrl.length - 1].toLowerCase();
  
        if (!currentUrl.includes("admin")) {
          if (currentUrl.includes("patientBasicInfo")) {
            this.patientId = localStorage.getItem("patientId");
            this.topHeading = "BASIC INFORMATION";
          } else if (
            currentUrl.includes("treatment") ||
            currentUrl.includes("pastHistory") ||
            currentUrl.includes("nextAppointment") ||
            currentUrl.includes("vitals") ||
            currentUrl.includes("complaints") ||
            currentUrl.includes("investigations") ||
            currentUrl.includes("growthnutrition")
          ) {
            this.patientId = localStorage.getItem("patientId");
            this.topHeading = "BASIC INFORMATION";
          } else {
            this.patientId = null;
          }
        }
  
        if (localStorage.getItem("isFromLanding") === "yes") {
          this.patientId = localStorage.getItem("patientId");
          const caseId = localStorage.getItem("viewCaseId") || localStorage.getItem("newCaseId");
          this.getVisits(caseId, this.patientId);
        }
  
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          mainContent.scrollTop = 0; // Scroll to the top of the main content
        }
  
        this.runOnRouteChange();
      });


      this.doctorService.getDetails({ user_id: this.userID }).subscribe((response:any) => {
        if (response.success) {
          console.log("docres",response)
          if (response.doctor.services && response.doctor.services !== null) {
            var services = JSON.parse(response.doctor.services);
            console.log("serices",services)
            this.doctorFlag=services.advancedPayment
            
            console.log("???????",this.doctorFlag)
          }
          // this.selectedDoctorId = response.doctor.id;
          // console.log("Mar1666",this.selectedDoctorId)
        }
      });

  }
  

  getPatientList(offset: any, limit: any) {
    let req_vars = { offset: offset, limit: limit };
    this.patientService.getAllPatients(req_vars).subscribe((response:any) => {
      if (response.success == true) {
        this.recentlyAuthorizedPatients = response.patients;
        this.recentlyAuthorizedPatients.map((o, i) => {
          this.patientsArray.push(o);
        });
        this.nextOffset = response.nextOffset;
      } else {
        this.toastr.error(response.msg);
      }
    });
  }

  checkIfAuthorized(patient: any, d: any) {
    let params:any = {
      user_id: this.userID,
      patient_id: patient.Patient.id
    };
    this.patientService.checkIfAuthorized(params).subscribe((response:any) => {
      if (response.authorized) {
        this.setPatient(patient, d);
      } else {
        this.authorizepatientId = patient.Patient.id;
        this.phoneNumber = response.phone_number;
        this.authorizePatient = true;
        this.modalName = patient;
        this.modalId = d;
        setTimeout(function () {
          const otpElement = document.getElementById("otp");
          if (otpElement) {
            otpElement.focus();
          }
        }, 200);
        setTimeout(function () {
          $("#resend-otp").removeClass("not-active");
        }, 120000);
      }
      /*if(!response.authorized){
          localStorage.setItem('url', state.url)
          localStorage.setItem('phone',response.phone_number)
          this.router.navigateByUrl(`doctor/patients/authorize/${patientId}`);
          return false
        }*/
      console.log(response);
    });
  }

  authorize() {
    if (!this.otp) {
      this.otp = null;
      this.toastr.error(
        "Please enter valid OTP or click on resend button to receive new OTP"
      );
    } else if (this.otp.length < 6) {
      this.otp = null;
      this.toastr.error(
        "Please enter valid OTP or click on resend button to receive new OTP"
      );
    } else {
      let params: any = {
        user_id: this.userID,
        patient_id: this.authorizepatientId,
        otp_action: "access_patient",
        otp: this.otp,
        phone_number: this.phoneNumber
      };
      this.patientService.authorizePatient(params).subscribe((respose:any) => {
        if (respose.success) {
          this.authorizePatient = false;
          this.phoneNumber = null;
          this.authorizepatientId = null;
          this.setPatient(this.modalName, this.modalId);
          this.otp = null;
        } else {
          this.toastr.error(respose.error);
        }
      });
    }
  }

  setPatient(patient:any, d:any) {
    this.patientId = patient.Patient.id;
    var req_param = { patient_id: patient.Patient.id, doctor_id: this.userID };
    this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.cases = response.patientCase;
        this.cloneCases = cloneDeep(this.cases);
        this.otherCases = false;
        this.open(this.ViewAllCases, "viewAllCasesPopup");
      }
    });
    // this.router.navigate([`/doctor/patients/patientBasicInfo/${patient.Patient.id}`])
    // d('close modal')
  }

  searchPatients() {
    if (this.searchPatient && this.searchPatient !== "") {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patientsArray = [];
        let req_vars = { search: this.searchPatient };
        this.patientService.searchPatients(req_vars).subscribe((response:any) => {
          if (response.success == true) {
            this.patientsArray = response.patients;
          } else {
            this.toastr.error(response.msg);
          }
        });
      }, 1000);
    } else {
      this.patientsArray = [];
      this.offset = 0;
      this.limit = this.limit;
      this.getPatientList(this.offset, this.limit);
    }
  }
  

  removeCase() {
    localStorage.removeItem("newCaseId");
    localStorage.removeItem("newVisitId");
    localStorage.removeItem("viewCaseId");
    localStorage.removeItem("viewVisitId");
  }

  addLabelClass(e:any) {
    if (
      e.target.parentElement.children &&
      e.target.parentElement.children.length > 3
    ) {
      if ($(e.target.parentElement.children[2]).hasClass("animatedLabel")) {
        $(e.target.parentElement.children[2]).addClass("animatedLabel");
      } else {
        $(e.target.parentElement.children[2]).removeClass("animatedLabel");
      }
    } else {
      $(e.target.parentElement.children[1]).addClass("animatedLabel");
    }
    // $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == "") {
      if (
        e.target.parentElement.children &&
        e.target.parentElement.children.length > 3
      ) {
        if ($(e.target.parentElement.children[2]).hasClass("animatedLabel")) {
          $(e.target.parentElement.children[2]).addClass("animatedLabel");
        } else {
          $(e.target.parentElement.children[2]).removeClass("animatedLabel");
        }
      } else {
        $(e.target.parentElement.children[1]).removeClass("animatedLabel");
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => this.runOnRouteChange());
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
  }

  runOnRouteChange(): void {
    this.topHeading = "";
    this.userID = this.authService.getUser();
    const urlArray = location.href.split("/");
    this.path = urlArray[urlArray.length - 1].toLowerCase();
    this.currentUrl = urlArray;
    //this.patientId = this.authService.getPatient()
    if (this.userID) {
      this.getUserDetails();
    }

    if (urlArray.indexOf("admin") == -1) {
      if (urlArray.indexOf("patientBasicInfo") != -1) {
        this.topHeading = "BASIC INFORMATION";
      } else if (urlArray.indexOf("vitals") != -1) {
        this.topHeading = "VITALS";
      } else if (urlArray.indexOf("complaints") != -1) {
        this.topHeading = "COMPLAINTS";
      } else if (urlArray.indexOf("investigations") != -1) {
        this.topHeading = "INVESTIGATIONS";
      } else if (urlArray.indexOf("treatment") != -1) {
        this.topHeading = "PRESCRIPTION";
      } else if (urlArray.indexOf("treatment") != -1) {
        this.topHeading = "NEXT APPOINTMENT";
      } else if (urlArray.indexOf("growthnutrition") != -1) {
        this.topHeading = "GROWTH & NUTRITION";
      } else if (this.path != "profileview" && this.path != "signup") {
        var d = new Date();
        var time = d.getHours();

        if (this.path == "thankyou") {
          this.topHeading = "LET'S GET STARTED";
        } else {
          if (time >= 4 && time <= 11) {
            this.topHeading =
              "Good Morning!" +
              " Dr. " +
              this.userDetails.first_name +
              " " +
              this.userDetails.last_name;
          } else if (time >= 12 && time <= 16) {
            this.topHeading =
              "Good Afternoon!" +
              " Dr. " +
              this.userDetails.first_name +
              " " +
              this.userDetails.last_name;
          } else if (time >= 17) {
            this.topHeading =
              "Good Evening!" +
              " Dr. " +
              this.userDetails.first_name +
              " " +
              this.userDetails.last_name;
          }
        }
      } else {
        this.topHeading = this.headingArray[this.path];
      }
    }

    if (this.isOver() || this.router.url === "/maps/fullscreen") {
      this.isOpened = false;
    }
    // let currentUrl = this.router.url.split('/')
    //
    // this.path = currentUrl.slice(-1)[0].toLowerCase()
    // this.mainMenu = currentUrl[2].toLowerCase()
    //
    // this.topHeading = this.headingArray[this.path]
    // // alert(this.topHeading)

    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.options = activeRoute.snapshot.data;
    });

    if (this.options) {
      if (this.options.hasOwnProperty("heading")) {
        this.setTitle(this.options.heading);
      }
    }
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(
      "Decima - Bootstrap 4 Angular Admin Template | " + newTitle
    );
  }
  toggleSidenav() {
    this.isOpened = !this.isOpened;
  }
  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent): void {
    if (!this.isOpened) {
      this.isOpened = true;  // Open the sidenav if it's closed
    }
  }

  toogleSidebar(): void {
    this.isOpened = !this.isOpened;
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 991px)`).matches;
  }

  openSearch(search:any) {
    this.searchPatient = null;

    this.modalRef = this.modalService.open(search, {
      windowClass: "search",
      backdrop: false
    });
  }

  removeData(idx:any) {
    if (idx != null) {
      this.selectedIndex = idx;
    }
    this.storageService.removeData();
    this.storageService.removeStage();
  }

  btnClick() {
    alert('Hola')
  }

  getUserDetails() {
    var req_param = { user_id: this.userID };
    this.userService.getUserDetails(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.userDetails = response.user;

        console.log("USER Details", this.userDetails)

        localStorage.setItem('docObj', JSON.stringify(this.userDetails));
                       
        
        if (this.currentUrl.indexOf("admin") == -1) {
          if (this.currentUrl.indexOf("patientBasicInfo") != -1) {
            this.topHeading = "BASIC INFORMATION";
          } else if (this.currentUrl.indexOf("vitals") != -1) {
            this.topHeading = "VITALS";
          } else if (this.currentUrl.indexOf("complaints") != -1) {
            this.topHeading = "COMPLAINTS";
          } else if (this.currentUrl.indexOf("investigations") != -1) {
            this.topHeading = "INVESTIGATIONS";
          } else if (this.currentUrl.indexOf("treatment") != -1) {
            this.topHeading = "PRESCRIPTION";
          } else if (this.currentUrl.indexOf("nextAppointment") != -1) {
            this.topHeading = "NEXT APPOINTMENT";
          } else if (this.currentUrl.indexOf("growthnutrition") != -1) {
            this.topHeading = "GROWTH & NUTRITION";
          } else if (this.path != "profileview" && this.path != "signup") {
            var d = new Date();
            var time = d.getHours();
            if (time >= 4 && time <= 11) {
              this.topHeading =
                "Good Morning!" +
                " Dr. " +
                this.userDetails.first_name +
                " " +
                this.userDetails.last_name;
            } else if (time >= 12 && time <= 16) {
              this.topHeading =
                "Good Afternoon12321234321232124!" +
                " Dr. " +
                this.userDetails.first_name +
                " " +
                this.userDetails.last_name;
            } else if (time >= 17) {
              this.topHeading =
                "Good Evening!" +
                " Dr. " +
                this.userDetails.first_name +
                " " +
                this.userDetails.last_name;
            }
          }
        }
        this.profileImage = response.user.profile_photo
          ? `${this.profileImagePath}${response.user.profile_photo}`
          :
          this.userDetails.gender.toLowerCase() == 'male' ?
            "assets/images/doctor_placeholder_male.svg"
            :
            "assets/images/doctor_placeholder_female.svg";
      }
    });
  }
  toggleMenu(menuitem: any): void {  
    console.log("menuItmesMar24",menuitem)

    menuitem.open = !menuitem.open; // Toggle the open property

    if (menuitem.open && !this.doctorFlag) {
      menuitem.children = menuitem.children.filter((child:any) => child.name !== "Payment");
    }
  }
  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }


  removePhoto() {
    if (confirm("Are you sure you want to remove this image?")) {
      const params = { user_id: this.userID, profile_photo: null as string | null };
      this.userService.removePhoto(params).subscribe((response:any) => {
        if (response.success == true) {
          this.getUserDetails();
        }
      });
    }
    /*this.confirmationService.confirm({
      message: 'Are you sure you want to remove this image?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        const params = {user_id: this.userID, profile_photo: null}
        this.userService.removePhoto(params).subscribe((response:any) =>{
          if(response.success == true){
            this.getUserDetails();
          }
        })
      }
    })*/
  }

  showCropper() {
    this.cropperDisplay = true;
    // this.openDialog(this.saveImageModal, 0)
  }

  hideCropper() {
    this.cropperDisplay = false;
    this.closeDialog();
  }

  showDialog() {
    this.display = true;
    document.body.className += " modal-open";
  }

  hideDialog() {
    this.display = false;
    document.body.className = document.body.className.replace("modal-open", "");
  }

  fileChangeListener($event:any) {
    this.hideDialog();
    this.cropper.reset();
    var image: any = new Image();
    var file: File = $event.target.files[0];

    //validations for profile image. Max file size allowed = 5MB
    if (file.type.match("image.*")) {
      this.imageError = "";
      var myReader: FileReader = new FileReader();
      var that = this;
      myReader.onloadend = function (loadEvent: any) {
        image.src = loadEvent.target.result;
        setTimeout(function () {
          that.cropper.setImage(image);
          // that.cropper.image = image
          that.showCropper();
          that.cropperDisplay = true;
        }, 500);
      };
      myReader.readAsDataURL(file);
    } else {
      this.toastr.error("Invalid file format.");
    }
  }

  selectFile() {
    document.getElementsByName("profile_pic")[0].click();
    this.closeDialog();
  }

  uploadPhoto() {
    var req_param = {
      profile_photo: this.data.image,
      id: this.userID,
      user_type: "Doctor"
    };
    this.userService.updateProfilePic(req_param).subscribe((response:any) => {
      if (response.success == true) {
        this.data.image = null;
        this.getUserDetails();
      }
    });
    //var req_params = {profile_picture: this.data.image}
  }
  closeLink() {
    this.mobileNumber = "";
  }
  shareLinkApp() {
    const postData = {
      mobileNumber: this.mobileNumber,
    }
    this.patientService.sendSmsOfAppInvite(postData).subscribe((response:any) => {
      if (response) {

        console.log(response)
        this.modalService.dismissAll();
      }
    })

  }

  logout() {
    localStorage.removeItem("lock");
    this.authService.deleteToken(0);
  }

  open(content: any, customClass: any = "") {
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.getUtilityDetails(customClass);
    this.modalRef = this.modalService.open(content, { backdrop: false, size: customClass });
    let searchField = document.getElementById("searchInput");

    if (searchField) {
      searchField.focus();
    }
    /*let that = this
    setTimeout(()=>{
      console.log(that.searchInput.nativeElement)
      that.searchInput.nativeElement.focus();
    },200)*/

    this.modalRef.result.then(
      (result:any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason:any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openn(content:any, type:any) {
    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
      this.isEditableVisit = false

    });
  }

  getConstants() {
    this.globalService.getConstants().subscribe((response:any) => {
      this.bloodGroups = response.blood_groups;
    });
  }

  getUtitlityDetails() { }

  initAddPatient(content:any) {
    this.getConstants();
    this.user = {};
    this.errors = {};
    this.error = false;
    this.user["age"] = {};
    this.user.blood_group = null;
    this.open(content, "addNewPatient");
    let d = new Date();
    this.date = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.minDate = { year: 1900, month: 1, day: 1 };
  }

  addPatient(c:any) {
    this.errors = {};
    if (!this.user["phone_number"]) {
      this.errors["phone_number"] = "Please enter patient's phone number";
    } else if (this.user["phone_number"].length < 10) {
      this.errors["phone_number"] = "Please enter valid phone number";
    } else if (!this.stdCode) {
      this.errors["phone_number"] = "Please enter country code";
    }

    // else if(this.user['phone_number'].length == 10){
    //   this.errors['phone_number'] = "Please enter country code"
    // }
    else if (!this.user["first_name"]) {
      this.errors["first_name"] = "Please enter patient's first name";
    } else if (!this.user["last_name"]) {
      this.errors["last_name"] = "Please enter patient's last name";
    } else if (!this.user["dob"]) {
      this.errors["dob"] = "Please select patient's date of birth";
    } else if (!this.user["gender"]) {
      this.errors["gender"] = "Please select patient's gender";
    }
    // else if(this.user['blood_group'] == null){
    //   this.errors['blood_group'] = "Please select patient's blood group"
    // }
    else if (this.stdCode == null) {
      this.errors["stdCode"] = "Please enter valid country code.";
    } else {
      this.user["first_name"].trim();
      this.user["last_name"].trim();
      this.user.user_type = "patient";
      this.user.otp_action = "add_patient";
      this.user.dob = this.parseDate(this.user.dob);
      this.user.user_id = this.userID;
      let number = this.user.phone_number;
      this.otpSection = false;
      this.user.phone_number = `${this.stdCode}${this.user.phone_number}`;
      this.showLoading = true;
      this.patientService.validate(this.user).subscribe((response:any) => {
        if (response.success) {
          // this.otp = null;
          // this.otpSection = true;
          // setTimeout(function () {
          //   document.getElementById("otp").focus();
          // }, 200);
          // setTimeout(function () {
          //   $("#resend-otp").removeClass("not-active");
          // }, 120000);

          let params = {
            // user_id: this.userId,
            otp_action: "add_patient",
            otp: '000000',
            user_id: this.userID,
            patient: this.user
          };
          this.createPatient(params, c);
          //120000
        } else {
          this.error = true;
          this.showLoading = false;
          this.user.phone_number = number;
          this.user.dob = this.reverseDate(this.user.dob);
          this.toastr.error(response.error);
        }
      });
    }
  }

  reqOtp() {
    let params = {
      phone_number: this.user["phone_number"],
      otp_action: "add_patient",
      user_id: this.userID
    };

    this.userService.generateOTP(params).subscribe((response:any) => {
      if (response.success) {
        $("#resend-otp").addClass("not-active");
        setTimeout(function () {
          $("#resend-otp").removeClass("not-active");
        }, 12000);
        this.toastr.success(
          "OTP has been sent to your registered mobile number"
        );
      } else {
        this.toastr.error(response.error);
      }
    });

    /*let params = {
      phone_number: this.user.phone_number,
      otp_action: 'add_patient',
      user_id: this.userID
    }*/
    /*this.toastr.success("OTP has been sent to your registered mobile number")
    $('#resend-otp').addClass('not-active')
    setTimeout(function(){
      $('#resend-otp').removeClass('not-active')
    }, 120000);*/
    /*this.userService.generateOTP(params).subscribe((response:any) => {
        if(response.success){
          this.toastr.success("OTP has been sent to your registered mobile number")
          $('#resend-otp').addClass('not-active')
          setTimeout(function(){
            $('#resend-otp').removeClass('not-active')
          }, 120000);
        }else{
          this.toastr.error(response.error)
        }
      })*/
  }

  closeCalender(e:any, d:any) {
    if (e.target.offsetParent == null) d.close();
    else if (
      e.target.offsetParent &&
      e.target.offsetParent.nodeName != "NGB-DATEPICKER" &&
      e.target.offsetParent.className.indexOf("calender") == -1 &&
      e.target.offsetParent.className.indexOf("custom-select") == -1
    )
      d.close();
  }

  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  parseDate(dateObject:any) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
  }

  getAge() {
    this.user["age"] = {};
    const { day, month, year } = this.user["dob"];
    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    // 0
    bdate.setFullYear(year);
    let age = this.calculateAge(new Date(), bdate);
    this.user["age"]["years"] = age[0];
    this.user["age"]["months"] = age[1];
    this.user["age"]["weeks"] = Math.floor(age[2] / 7);
  }

  calculateAge(endingDate:any, startingDate:any) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    return [yearDiff, monthDiff, dayDiff];
  }

  private getDismissReason(reason: any): any {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // return 'by clicking on a backdrop dasdasdasdasdad';
    } else {
      // return  `with: ${reason}`;
    }
  }

  onKeyUp(event:any, id:any, nextInputIndex:any) {
    let keyCodeValue = event.keyCode;
    let input = event.target;
    let nexInput, newID;

    if (
      (keyCodeValue != 32 && event.key >= 0 && event.key <= 9) ||
      keyCodeValue == 8 ||
      keyCodeValue == 13
    ) {
      if (keyCodeValue === 8) {
        nexInput = nextInputIndex - 1;
        newID = id + nexInput;
      } else if (keyCodeValue === 9) {
        nexInput = nextInputIndex;
        newID = id + nexInput;
      } else {
        nexInput = nextInputIndex + 1;
        newID = id + nexInput;
      }
      if (nexInput <= 5 && nexInput >= 0) {
        const element = document.getElementById(newID);
        if (element) {
            element.focus();
        }
      }
    } else {
      $("#tab" + nextInputIndex).val("");
      return false;
    }
  }

  goBackBtn() {
    this.otpSection = false;
    this.authorizePatient = false;
    this.otp = null;
  }

  createPatient(params:any, c:any) {
    // if (!this.otp) {
    //   this.otp = null;
    //   this.toastr.error(
    //     "Please enter valid OTP or click on resend button to receive new OTP"
    //   );
    // } else if (this.otp.length < 6) {
    //   this.otp = null;
    //   this.toastr.error(
    //     "Please enter valid OTP or click on resend button to receive new OTP"
    //   );
    // } else {

    this.patientService.create(params).subscribe((response:any) => {
      if (response.success) {
        //this.router.navigate['doctor/patients']
        c("close modal");
        this.otpSection = false;
        this.showLoading = false;
        this.otp = null;
        this.toastr.success(`Record of ${this.user["first_name"]} ${this.user["last_name"]} has been created successfully`);
        localStorage.removeItem("viewCaseId");
        localStorage.removeItem("viewVisitId");
        localStorage.setItem("newCaseId", response.newCase.id);
        localStorage.setItem("newVisitId", response.newVisit.id);
        localStorage.setItem("patientId", response.patient.id);
        this.patientServiceReload.reloadPatients();
      } else {
        this.toastr.error(response.error);
        this.showLoading = false;

      }
    });
    // }
  }

  createNewCase(d:any) {
    localStorage.removeItem("viewCaseId");
    localStorage.removeItem("viewVisitId");
    this.confirmationService.confirm({
      message:
        "Creating a new case for your existing patient will automatically ‘Close’ the previously open case (if any). Please click ‘Proceed’ to create new case.",
      header: "Confirmation",
      icon: "fa fa-question-circle",
      acceptLabel: "Proceed",
      rejectLabel: "Cancel",
      accept: () => {
        let req_vars = { user_id: this.userID, patient_id: this.patientId };
        this.patientService.createCase(req_vars).subscribe((response:any) => {
          if (response.success == true) {
            localStorage.setItem("newCaseId", response.newCase.id);
            localStorage.setItem("newVisitId", response.newVisit.id);
            this.caseIdheader = response.newCase.id;
            this.getVisits(response.newCase.id, this.patientId);
            localStorage.setItem("patientId", this.patientId);
            this.router.navigate([`/doctor/patients/vitals/edit`]);
            d("close modal");
          }
        });
      },
      reject: () => { }
    });
  }

  createNewVisit(case_id:any, d:any) {
    localStorage.removeItem("viewCaseId");
    localStorage.removeItem("viewVisitId");
    let req_vars = {
      caseId: case_id,
      patient_id: this.patientId,
      doctor_id: this.userID
    };
    this.patientService.createVisit(req_vars).subscribe((response:any) => {
      if (response.success == true) {
        localStorage.setItem("newCaseId", response.patientCase.id);
        localStorage.setItem("newVisitId", response.visit.id);
        this.caseIdheader = response.patientCase.id;
        if (d) {
          d("close modal");
        }
        this.getVisits(response.patientCase.id, this.patientId);
        localStorage.setItem("patientId", this.patientId);
        this.router.navigate([`/doctor/patients/vitals/edit`]);
      }
    });
  }

  viewVisitDetails(case_id:any, visit_id:any, d:any) {
    localStorage.setItem("viewCaseId", case_id);
    localStorage.setItem("viewVisitId", visit_id);

    localStorage.removeItem("newCaseId");
    localStorage.removeItem("newVisitId");
    if (d) {
      d("close modal");
    }
    this.getVisits(case_id, this.patientId);
    this.caseIdheader = case_id;
    localStorage.setItem("patientId", this.patientId);
    this.router.navigate([`/doctor/patients/vitals/view`]);
  }

  getVisits(case_id:any, patient_Id:any) {
    let req_params = { caseId: case_id, patient_id: patient_Id };
    this.patientService.getVisits(req_params).subscribe((response:any) => {
      if (response.success == true) {
        this.allVisits = response.visits;
        this.caseStatus = response.case_status;
        this.caseIdheader = case_id;
        localStorage.setItem("isFromLanding", "no");
      }
    });
  }

  showOtherCases(otherCases:any) {
    if (otherCases) {
      var req_param = { patient_id: this.patientId };
      this.patientService.getCasesAndVisits(req_param).subscribe((response:any) => {
        if (response.success == true) {
          this.cases = response.patientCase;
        }
      });
    } else {
      this.cases = this.cloneCases;
    }
  }

  //function to toggle open case id
  toggleIsOpen(caseId:any) {
    this.openCaseId = this.openCaseId == caseId ? "0" : caseId;
  }

  validateNumber(e:any) {
    return (
      (e.charCode > 47 && e.charCode < 58) ||
      e.charCode == 46 ||
      e.which == 8 ||
      e.which == 13 ||
      e.keyCode == 9
    );
  }

  validateUser() {
    if (this.password) {
      let params = {
        user_id: this.userID,
        password: this.password
      };
      this.userService.checkPassword(params).subscribe((response:any) => {
        if (response.success) {
          this.password = null;
          this.idle = false;
          localStorage.removeItem("lock");
        } else {
          this.toastr.error("Please enter correct password");
        }
      });
    } else {
      this.toastr.error("Please enter correct password");
    }
  }

  genderToggle(current:any) {
    if (current == "yes") {
      this.user.gender_type = "non_specified";
      this.user.gender = null;
    } else {
      this.user.gender_type = null;
    }
  }

  handleDrawerClick(e:any) {
    const parent:any = document.getElementById("utilityDawer");
    const child = e.target;
    if (parent != child && parent.contains(child)) {
      if (e.target.className.indexOf("drawerToggle") > -1) {
        this.showSettings = !this.showSettings;
      } else if (e.target.className.indexOf("drawerCloser") > -1) {
        this.showSettings = false;
      } else {
        // do nothing
      }
    } else {
      this.showSettings = false;
    }
  }

  openUrl(url:any) {
    window.open(url)
  }
}
