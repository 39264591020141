import { Component, OnInit, OnDestroy, ViewChild, OnChanges, HostListener, NgZone, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service'
import { AdminMenuItems } from '../../shared/menu-items/menu-items';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import $ from 'jquery';
import { MatSidenav } from "@angular/material/sidenav";

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('sidenav') sidenav: MatSidenav;
  private _routerSubscription: Subscription = new Subscription(); // Initialize here
  mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  currentUrl: string[] = [];
  currentLang = 'en';
  options!: Options;
  theme = 'light';
  showSettings = false;
  isDocked = false;
  isBoxed = false;
  isOpened = true;
 isOpenMenu=true;
  mode = 'push';
  _mode = this.mode;
  _autoCollapseWidth = 991;
  width = window.innerWidth;
  topHeading: string = "LET'S GET STARTED"
  path: string = ""
  doctor: string = "Dr. Rashmi Gapchup"
  headingArray: any = []
  closeResult!: string;

  todaysDate: any
  mainMenu: any = "";

  constructor(
    public menuItems: AdminMenuItems,
    private router: Router,
    private route: ActivatedRoute,
    //public translate: TranslateService,
    private modalService: NgbModal,
    private titleService: Title,
    private authService: AuthService,
    private zone: NgZone) {
    // Removed unnecessary duplicate initialization
  }

  ngOnInit(): void {
    // Subscribe to router events and filter for NavigationEnd
    this._routerSubscription = this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd) // Type guard to ensure the event is NavigationEnd
      )
      .subscribe((event: NavigationEnd) => {
        // Log the navigation URL after redirects
        console.log('Navigated to:', event.urlAfterRedirects);

        // Here you can add any other code you want to execute when a new route is navigated to
        const currentUrl = event.url.split('/');
        this.currentUrl = currentUrl;
        this.path = currentUrl[currentUrl.length - 1].toLowerCase();

        // Perform any logic related to route changes
        this.getTopHeading();

        // Scroll the main content to the top after route change
        const mainContent = document.querySelector('.main-content');
        if (mainContent) {
          mainContent.scrollTop = 0; // Scroll to the top
        } else {
          console.warn('Element with class "main-content" not found.');
        }
      });

    // Initial setup for today's date and window size
    this.todaysDate = Date.now();
    if (this.isOver()) {
      this._mode = 'push';
      this.isOpened = false;
    }
  }

  ngAfterViewInit(): void {
    setTimeout((_: any) => this.runOnRouteChange());
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    setTimeout((_:any) => this.runOnRouteChange());
  }

  goToMarktPlace() {

    this.router.navigate([`/admin/listedProducts`], { queryParams: {} });

  }
  toggleSidenav() {
    this.isOpenMenu = !this.isOpenMenu;
  }
  gToMarktPlace() {
    this.router.navigate([`/admin/marketPlaceData`], { queryParams: {} });

  }
  runOnRouteChange(): void {
    if (this.isOver() || this.router.url === '/maps/fullscreen') {
      this.isOpened = false;
    }
    let currentUrl = this.router.url.split('/')
    this.currentUrl = currentUrl
    this.path = currentUrl.slice(-1)[0].toLowerCase()
    this.mainMenu = currentUrl[2] ? currentUrl[2].toLowerCase() : null

    this.getTopHeading()
    // alert(this.topHeading)
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.options = activeRoute.snapshot.data;
    });

    if (this.options) {
      if (this.options.hasOwnProperty('heading') && typeof this.options.heading === 'string') {
        this.setTitle(this.options.heading);
      }
    }
  }

  getTopHeading() {
    console.log(this.currentUrl)
    if (this.currentUrl.indexOf('doctors') != -1) {
      this.topHeading = "Doctor Management"
    }
    else if (this.currentUrl.indexOf('patients') != -1) {
      this.topHeading = "Patient Management"
    }
    else if (this.currentUrl.indexOf('customers') != -1) {
      this.topHeading = "Customer Management"
    }
    else if (this.currentUrl.indexOf('labs') != -1) {
      this.topHeading = "Lab Management"
    } else if (this.currentUrl.indexOf('activities') != -1) {
      this.topHeading = "Activity Log"
    } else {
      this.topHeading = ""
    }
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle('Decima - Bootstrap 4 Angular Admin Template | ' + newTitle);
  }

  toogleSidebar(): void {
    this.isOpened = !this.isOpened;
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 991px)`).matches;
  }


  openSearch(search:any) {
    this.modalService.open(search, { windowClass: 'search', backdrop: false });
  }
  open(content:any, customClass: any = '') {
    this.modalService.open(content, { size: customClass }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): any {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // return 'by clicking on a backdrop dasdasdasdasdad';
    } else {
      // return  `with: ${reason}`;
    }
  }
  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  logout() {
    this.authService.deleteAdminToken(0)
    //this.router.navigate(['/admin/login'])
  }

}
