import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import $ from 'jquery';
import { Router } from '@angular/router';
import { openClose } from '../../animations'
import { AuthService } from '../../services/auth.service'
import { UserService } from '../../services/user.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  @ViewChild('divDoctorInfo') divDoctorInfo?: ElementRef;
  @ViewChild('divSuccessfulReach') divSuccessfulReach?: ElementRef;
  cliniciansFamilySection = true;
  loginSec = false;
  mobNo = true;
  successMessage = true;
  doctorForm = true;
  OTPsec = false;
  passwordSec = false;
  optSec = false;
  phoneNumber: any = null
  password: any = null
  user: any = null
  otpArray: any = new Array(6)
  otp:any = null
  docName: string = '';
  docphoneNumber: string = '';
  userName: string = '';
  userPhoneNumber = '';
  registrationNumber: string = '';
  mask: any[] = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  constructor(public router: Router, private authService: AuthService, private toastr: ToastrService, private userService: UserService,) {
    if (this.authService.isLoggedIn()) {
      var labdoc = localStorage.getItem("LAB_DOCTOR");
      if(labdoc){
        var labdocobj =JSON.parse(labdoc);
        if(labdocobj.role == "l"){
          this.router.navigate(['/lab/labHomePage']);
        } else {
          this.router.navigate(['/doctor/homepage'])
        }
      } else {
        this.router.navigate(['/doctor/homepage'])
      }
      
    }
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    // Use optional chaining to safely access ElementRefs
    this.divDoctorInfo?.nativeElement; // Use safely
    this.divSuccessfulReach?.nativeElement; // Use safely
  }

  opdSignIn() {
    console.log("routing to doc login");
    this.router.navigate(['/login'])
  }
  loginBtn() {
    this.loginSec = false;
    this.cliniciansFamilySection = false;
    this.passwordSec = true;
  }


  // @ViewChild('docName') docName: ElementRef;
  // @ViewChild('phoneNumber') phoneNumber: ElementRef;
  // @ViewChild('registrationNumber') registrationNumber: ElementRef;
  sendDocDetaillsBtn() {

    // this.mobNo = false;
    // this.successMessage = true;

    // let docName = this.docName.nativeElement.value;
    // let phoneNumber = this.docphoneNumber.nativeElement.value;
    // let registrationNumber = this.registrationNumber.nativeElement.value;


    // console.log('Doc - '+this.docName+ ' '+this.docphoneNumber, 'MY - '+this.yourName+ ' '+this.yourPhoneNumber)
    //comment here
    if (this.docName != "" && this.docphoneNumber != "") {
      if (this.docphoneNumber && this.docphoneNumber.length > 7) {
        let message = ''
        if (this.userName || this.userPhoneNumber) {
          message = `Hello All, \nDoctor ${this.docName} (${this.docphoneNumber}) referenced by ${this.userName} (${this.userPhoneNumber}) has send an enquiry.\nKindly look forward to onboard the doctor`

        } else {
          message = `Hello All, \nDoctor ${this.docName} (${this.docphoneNumber}) has send an enquiry.\nKindly look forward to onboard the doctor`

        }

        console.log('Message - ', message)

        this.sendDoctorsDetails(message);
      } else {
        this.toastr.error("Please enter a valid phone number.")
      }

    } else {
      this.toastr.error("Please enter doctor's name, doctor's contact number")
    }

  }


  sendDoctorsDetails(message: any) {
    let params = {
      emailmessage: message
    };
  
    console.log(params);
    this.userService.sendDoctorEnquiryEmail(params).subscribe((response:any) => {
      if (response.success) {
        this.doctorForm = false;
        // Check if divSuccessfulReach is defined
        if (this.divSuccessfulReach) {
          this.divSuccessfulReach.nativeElement.style.display = 'block';
        }
      } else {
        this.toastr.error(response.error);
      }
    });
  }
  

  checkPhoneNumber() {
    if (this.phoneNumber == null || this.phoneNumber == "" || this.phoneNumber.length < 10) {
      this.toastr.error("Please enter a valid mobile number")
    } else {

      let params = {
        phone_number: `91${this.phoneNumber}`
      }
      this.userService.checkPhoneNumberandSendOTP(params).subscribe((response:any) => {
        if (response.success) {
          this.user = response.user
          this.phoneNumber = response.user.phone_number;
          this.loginSec = false;
          this.passwordSec = false;
          this.otp = null
          this.OTPsec = true;
          setTimeout(function () {
            const otpElement = document.getElementById('otp');
            if (otpElement) { // Check if otpElement is not null
              otpElement.focus();
            }
          }, 200);
          
          setTimeout(function () {
            $('#resend-otp').removeClass('not-active')
          }, 45000);
        } else {
          this.toastr.error(response.error)
        }
      })
    }
  }

  passwordLoginBtn() {
    this.loginSec = false;
    this.cliniciansFamilySection = false;
    this.passwordSec = false;
    this.optSec = true;
  }
  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }

  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }
}
