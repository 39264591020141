
<div class="app opd-main-wrapper" 
  [ngClass]="{
    'mode-boxed': isBoxed, 
    'sidebar-opened': isOpened, 
    'theme-light': theme == 'light', 
    'theme-dark': theme == 'dark', 
    'no-footer': options?.removeFooter, 
    'map-header': options?.mapHeader
  }"
  style="display: flex; width: 100%; height: 100%; justify-content: space-between;">
  <mat-sidenav-container class="sidenav-container" style="width: 185px; display: flex; height: 110vh; justify-content: center; align-items: center; border-right: 1px solid #eee;">
    <!-- Sidebar -->
    <mat-sidenav mode="over" [opened]="isOpened" (backdropClick)="isOpened = false" #sidenav (keydown.escape)="toggleSidenav()" disableClose style="background-color: #4687BF;">
      <nav
        style="font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px; ">
        <a [routerLink]="['/doctor/homepage']" title="home">
          <img alt="Logo" width="75px" src="assets/images/logo1.svg">
        </a>
      </nav>
  
     
  
  
  
  
    <!-- Main Content -->
    <mat-sidenav-content>
      <!-- main navigation -->
      <nav
  
  style="font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 15px 0 0 15px; border-right: 1px solid #eee;"
      class="menu" *ngIf="path != 'signup' && path != 'thankyou'"
      style="border-right: 1px solid #eee; margin-right: 0px; padding: 10px 5px; padding-right: 0px;">
      <ul class="navigation" appAccordion>
       <li class="navigation-item" appAccordionLink *ngFor="let menuitem of menuItems.getAll()"
       group="{{ menuitem.state }}">
       <a class="navigation-link" routerLinkActive="active" appAccordionToggle [routerLink]="['/', menuitem.state]"
         *ngIf="menuitem.type === 'link'">
         <span>{{ menuitem.name }}</span>
         <span class="mr-auto"></span>
         <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
       </a>
 
       <a class="navigation-link" routerLinkActive="active" appAccordionToggle
         [routerLink]="['/', 'admin', menuitem.state]" *ngIf="menuitem.type === 'extLink'">
         <span>{{ menuitem.name }}</span>
         <span class="mr-auto"></span>
         <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
       </a>
       <a class="navigation-link" routerLinkActive="active" appAccordionToggle
         [routerLink]="['/', 'admin', menuitem.state]" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
         <span>{{ menuitem.name }}</span>
         <span class="mr-auto"></span>
         <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
         <i class="menu-caret fa fa-caret-right"></i>
       </a>
 
       <a class="navigation-link " appAccordionToggle href="javascript:;"
         [ngClass]="{ active: currentUrl.indexOf(menuitem.state) != -1 }" *ngIf="menuitem.type === 'sub'">
         <span>{{ menuitem.name }}</span>
         <span class="mr-auto"></span>
         <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
         <i class="menu-caret fa fa-caret-right"></i>
       </a>
       <a class="navigation-link {{
         mainMenu == menuitem.state ? 'active1' : ''
       }}" appAccordionToggle href="javascript:;" [routerLink]="['/', 'doctor', 'investigations', 'viewRecords']"
         *ngIf="
         menuitem.type === 'sub' && menuitem.state === 'investigations'
       ">
         <span>{{ menuitem.name }}</span>
         <span class="mr-auto"></span>
         <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
         <i class="menu-caret fa fa-caret-right"></i>
       </a>
       <ul class="navigation-submenu" *ngIf="menuitem.type === 'sub'">
         <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
           <a [routerLink]="[
             '/',
             'admin',
             childitem.state,
             childitem.substate
           ]" class="navigation-link relative" [ngClass]="{
             active: currentUrl.indexOf(childitem.substate) != -1
           }" routerLinkActive="active">{{ childitem.name }}</a>
         </li>
       </ul>
     </li>
 
     <li class="navigation-item">
       <a (click)="gToMarktPlace();" style="color: #fff;" class="navigation-link" routerLinkActive="active" target="_blank">
         <span>Market Place</span>
         <span class="mr-auto"></span>
 
 
       </a>
     </li>
 
 
      </ul>
 
      <!-- SHARE APP LINK -->
     
 
 
    </nav>
 
   </mat-sidenav-content>
 





 <!-- LOGOUT -->

    <nav class="menu botttomMenu" *ngIf="path !== 'signup'  && path != 'thankyou'">

     
      <a class="navigation-link" (click)="logout()" style="height: 50px !important; background-color: #fff;">
        <div style="flex-direction: row; display: flex;  align-self: center;">

          <img src="assets/images/logout.svg" class="navbar-avatar rounded-circle svg" alt="user" title="user" style="filter: invert(100%);">

          <div style="margin-left: 6px; font-size: 18px; " >
            Logout</div>
        </div>
      </a>
    </nav>
 <!-- LOGOUT -->
    <nav class="menu botttomMenu" *ngIf="path !== 'signup'  && path != 'thankyou'">

     
      <a class="navigation-link" (click)="logout()" style="height: 50px !important; background-color: #fff;">
        <div style="flex-direction: row; display: flex;  align-self: center;">

          <img src="assets/images/logout.svg" class="navbar-avatar rounded-circle svg" alt="user" title="user" style="filter: invert(100%);">

          <div style="margin-left: 6px; font-size: 18px; ">
            Logout</div>
        </div>
      </a>



    </nav>
    <!-- /main navigation -->
  </mat-sidenav>
</mat-sidenav-container>

  <div p-sidebar-content class="app-inner" style="width: 100%; overflow-y: scroll; height: 100%;">
    <!-- old header -->
    <!-- <nav class="navbar custom-navbar bg-faded main-header"> -->
    <!-- <nav style="height: 5px;background-color:#195BA9;"> -->
    <nav style="height: 0px;">
      <ul class="navbar-nav">
        <li class="nav-item">
          <span class="topHeading" ></span>
          <!--<a  class="nav-link" (click)="toogleSidebar()">
            <i class="hamburger-icon v2">
              <span></span>
            </i>

          </a>-->
        </li>
      </ul>
      <span class="navbar-heading hidden-xs-down">{{options?.heading}}</span>
      <span class="mr-auto"></span>
      <!-- <ul class="addNewSearchSection clearfix" *ngIf="path != 'signup'">

        <li class="todayBirthday li">
          <a>{{todaysDayOfWeek}}<br />{{todaysDate | date:'d MMMM, yyyy'}}</a>
        </li>
      </ul> -->
      <ul class="navbar-nav hidden">
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a  class="nav-link" routerLinkActive="active" ngbDropdownToggle>
            <img src="assets/images/avatar.jpg" class="navbar-avatar rounded-circle" alt="user" title="user">
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" routerLinkActive="active" >
              <i class="icon icon-basic-settings mr-3"></i>
              <span>Settings</span>
            </a>
            <a routerLink="/doctor/profileView" class="dropdown-item" routerLinkActive="active">
              <i class="icon icon-basic-postcard mr-3"></i>
              <span>Profile</span>
            </a>
            <a class="dropdown-item" routerLinkActive="active" >
              <i class="icon icon-basic-message-multiple mr-3"></i>
              <span>Notifications</span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item"  routerLinkActive="active">
              <i class="icon icon-arrows-switch-vertical mr-3"></i>
              <span>Signout</span>
            </a>
          </div>
        </li>
      </ul>
    </nav>

    <div class="main-content">

      <router-outlet ></router-outlet>

    

   
    </div>
  </div>

</div>
















<!-- <ng-template #saveImageModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;"> Upload your profile
        picture
      </h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">


      <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
      <p class="errors" *ngIf="imageError">{{imageError}}</p>
      <div class="col-lg-12 cropper_buttons pl-0" style="display: flex; justify-content: flex-end; padding-top: 8px;">
        <button type="button" class="btn-primary opd-primary-btn btn-md mr-2 newBtnStyle" style="text-transform: none;"
          (click)="hideCropper(); uploadPhoto();" [disabled]="!data.image">Save</button>
        <button type="button" class="btn-primary opd-secondary-btn btn-md"
          style="text-transform: none; padding: 6px 10px;" (click)="hideCropper();">Cancel</button>
      </div>


    </div>

  </div>
</ng-template> -->

<!-- <p-dialog appendTo="body" [modal]="true" [draggable]="false" [blockScroll]="true" [closable]="true"
  (onHide)="hideDialog()" [(visible)]="display">
  <p-header style="font-size: 18px;">
    Select
  </p-header>
  <div class="row upload_buttons"><button type="button" class="newBtnStyle" (click)="selectFile()">Choose Image</button>
  </div>
  <div class="row upload_buttons"><button type="button" class="btn-primary opd-secondary-btn btn-md"
      style="text-transform: none; font-size: 14px;" (click)="hideDialog(); removePhoto();"
      [disabled]="!userDetails.profile_photo">Remove</button></div>

</p-dialog> -->





<p-confirmDialog class="pConfirmZindex" appendTo="body"></p-confirmDialog>






