import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service'
import { ToastrService } from 'ngx-toastr';
import $ from 'jquery';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentials: any
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {

    if (this.authService.isAdminLoggedIn()) {
      this.router.navigate(['/admin/otps'])
    }
  }

  ngOnInit() {
    this.credentials = { user_type: "Admin" }
  }
  login() {
    this.authService.adminLogin(this.credentials).subscribe((response:any) => {

      if (response.success == true) {
        this.authService.setAdminToken(response.token)
        this.authService.setUserType('admin')
        this.router.navigate(['/admin/reports'])
      } else {
        this.toastr.error(response.error)

      }
    })
  }
  addLabelClass(e:any) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e:any) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

}
