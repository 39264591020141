import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';


import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
// import { AgmCoreModule } from '@agm/core';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { ImageCropperModule } from "ngx-img-cropper";
import { DialogModule } from 'primeng/dialog';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component'
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModuleApp } from './shared/shared.module';
import { SigninComponent } from './authentication/signin/signin.component'
import { GlobalService } from './services/global.service'
import { UserService } from './services/user.service'
import { DoctorService } from './services/doctor.service'
import { MarketPlaceService } from './services/market-place.service'
import { PatientService } from './services/patient.service'
import { AdminService } from './services/admin.service'
import { StorageService } from './services/storage.service'
import { AuthService } from './services/auth.service'
import { UtilService } from './util/utilService'
import {MatNativeDateModule} from '@angular/material/core';
// import { StorageServiceModule } from 'ngx-webstorage-service'
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component'
import { LoginComponent } from './admin-panel/login/login.component';
import { ForgotPasswordComponent } from './admin-panel/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './admin-panel/reset-password/reset-password.component';
import { WebcamModule } from 'ngx-webcam';
import {MatSidenavModule} from '@angular/material/sidenav';
import { DoctorModule } from './doctor/doctor.module';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AuthGuard } from '../auth/auth.guard';

import { AdminAuthGuard } from '../auth/adminAuth.guard';
import { PatientAuthGuard } from '../auth/patientAuth.guard';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { NgbDateCustomParserFormatter } from './filters/dateformate';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { patientServiceReload } from '../app/doctor/patient/patient/patient.service'
import {MatIconModule} from '@angular/material/icon';
// New imports for graph functionality
//import { GraphsComponent } from './graphs/graphs.component';
//import { ChartComponent } from './chart/chart.component';
//import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
//import stock from 'highcharts/modules/stock.src';
//import more from 'highcharts/highcharts-more.src';
import { CommonModule, DatePipe } from '@angular/common';

import { ProgressComponent } from './doctor/progress/progress-compoent';
import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import { ChartModule } from 'angular-highcharts';
import { AppointmentProgressCardComponent } from './components/appointment-progress-card/appointment-progress-card.component';
import { ButtonComponent } from './components/button/button.component';
import { LabSidebarComponent } from './layouts/lab-sidebar/lab-sidebar.component';
import { DoctorPublicProfileComponent } from './doctor-public-profile/doctor-public-profile.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatButton, MatButtonModule } from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';

import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
;

import { MatSelectModule } from '@angular/material/select';
import { NewRxModule } from './new-rx/new-rx.module';
import { NewRxSidebarComponent } from './layouts/new-rx-sidebar/new-rx-sidebar.component';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'; // Import the new API


import { RemovePatientAccountComponent } from './remove-account/patient/remove-patient-account/remove-patient-account.component'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RemoveDoctorAccountComponent } from './remove-account/doctor/remove-doctor-account/remove-doctor-account.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';

// import { AlertboxComponent } from './components/alertbox/alertbox.component';



// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

/*
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}
*/
const dialogMock = {
  close: () => { }
};

(ConfirmDialogModule.prototype as any)['appendTo'] = function (): void {
  if (this.appendTo) {
    if (this.appendTo === 'body')
      document.body.appendChild(this.el.nativeElement);
    else
      this.domHandler.appendChild(this.container, this.appendTo);
  }
};
//const config: SocketIoConfig = { url: 'http://localhost:3000/?abcd=qef', options: {withCredentials: false} };

@NgModule({ declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        SigninComponent,
        DashboardLayoutComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        // AppointmentProgressCardComponent,
        ButtonComponent,
        LabSidebarComponent,
        DoctorPublicProfileComponent,
        NewRxSidebarComponent,
        RemovePatientAccountComponent,
        RemoveDoctorAccountComponent,
        // AlertboxComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA], imports: [NewRxModule,
        BrowserModule,
        CommonModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        SharedModuleApp,
        RouterModule.forRoot(AppRoutes, { useHash: false }),
        FormsModule,
        MatSidenavModule,
        MatDialogModule,
        
        SidebarModule,
        // AgmCoreModule.forRoot({ apiKey: 'YOURAPIKEY' }),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-full-width',
            preventDuplicates: true,
        }),
        ImageCropperModule,
        DialogModule,
        MatIconModule,
        MatSidenavModule,
        ConfirmDialogModule,
        SharedModule,
        WebcamModule,
        NgbModule,
        MatExpansionModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTooltipModule,
        DoctorModule], providers: [AuthGuard,
        PatientAuthGuard,
        provideHttpClient(withInterceptorsFromDi()),
        AdminAuthGuard,
        UserService,
        DoctorService,
        MarketPlaceService,
        StorageService,
        GlobalService,
        PatientService,
        AdminService,
        ConfirmationService,
        UtilService,
        AuthService,
        DatePipe,
        patientServiceReload,
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: MatDialogRef,
            useValue: dialogMock
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true } }
        //,{ provide: HIGHCHARTS_MODULES, useFactory: highchartsModules } // add as factory to your providers
        , provideHttpClient(withInterceptorsFromDi())] })




export class AppModule { }
