<!-- <div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div> -->
<div class="app opd-main-wrapper" [ngClass]="{
    'mode-boxed': isBoxed, 
    'sidebar-opened': isOpened, 
    'theme-light': theme == 'light', 
    'theme-dark': theme == 'dark', 
    'mode-push': _mode == 'push', 
    'mode-dock': _mode == 'dock', 
    'mode-over': _mode == 'over', 
    'mode-slide': _mode == 'slide'
  }" style="background-color: #fff;">
  <mat-sidenav-container class="sidenav-container" [style.width]="isDocked ? '80px' : '140px'">
    <!-- Sidenav -->
    <mat-sidenav #sidenav [mode]="'over'" [(opened)]="isOpened" [disableClose]="!isOver()" [fixedInViewport]="true"
      class="mat-sidenav" [ngStyle]="{'width': isDocked ? '80px' : '140px'}">

      <!-- Logo Section -->
      <nav class="sidenav-header"
        style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px;">
        <a [routerLink]="['/lab/labHomePage']" title="home">
          <img alt="Logo" width="65px" src="assets/images/new_logo.svg">
        </a>
      </nav>

      <!-- Main Navigation -->
      <nav class="menu"
        style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; flex-direction: column; padding: 12px; border-right: 1px solid #eee;">
        <ul class="navigation" appAccordion>
          <li class="navigation-item" appAccordionLink *ngFor="let menuitem of menuItems.getAll()"
            group="{{menuitem.state}}">
            <a class="navigation-link" routerLinkActive="active"
              (click)="otpSection = false; removeCase(); removeData();" [routerLink]="['/', 'lab', menuitem.state]"
              *ngIf="menuitem.type === 'link'" style="color: #000;">
              {{ menuitem.name }}
            </a>
            <a class="navigation-link" routerLinkActive="active"
              (click)="otpSection = false; removeCase(); removeData(menuitem.name=='Home' ? 0 : 1);" appAccordionToggle
              [routerLink]="['/','doctor',menuitem.state]" *ngIf="menuitem.type === 'extLink'"
              style="height: 52px !important; border-radius: 6px; width: 52px;"
              [ngStyle]="{'background-color' : selectedIndex == 0 && menuitem.name=='Home' ? '#E2ECFB' : selectedIndex == 1 && menuitem.name=='View Rx' ? '#E2ECFB' : 'transparent'}">

              <div style="flex-direction: column; display: flex; justify-content: center; width: 55px;">
                <div style="display: flex; justify-content: center;">
                  <mat-icon class="blue-icon" *ngIf="menuitem.name=='Home' && selectedIndex == 0" alt="user"
                    title="Home">home</mat-icon>
                  <mat-icon *ngIf="menuitem.name=='Home' && selectedIndex != 0" alt="user" title="Home">home</mat-icon>
                  <mat-icon *ngIf="menuitem.name=='Settings' && selectedIndex == 1">settings</mat-icon>
                  <mat-icon *ngIf="menuitem.name=='Settings' && selectedIndex != 1">settings</mat-icon>
                </div>
              </div>
              <span class="mr-auto"></span>
              <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            </a>
          </li>
          <li class="navigation-item" style="margin-left: 22px;">
            <div (click)="logout()"
              style="cursor: pointer; flex-direction: column; display: flex; justify-content: center; width: 55px;">
              <mat-icon alt="user" title="Logout">logout</mat-icon>
            </div>
          </li>
        </ul>
      </nav>
    </mat-sidenav>

    <!-- Main Content -->
    <mat-sidenav-content>
      <div>
        <!-- Your main application content goes here -->
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>


  <div p-sidebar-content class="app-inner">



    <div class="main-content">

      <router-outlet></router-outlet>

    </div>
  </div>

</div>



<!-- utility dower popup designs  -->





<!-- utility popups ends he -->










<p-confirmDialog class="pConfirmZindex" appendTo="body"></p-confirmDialog>